<template>
  <b-modal v-model="show" hide-header centered scrollable id="modal-filter-casting-calls" size="lg">
    <div class="p-0 p-md-1">
      <div class="d-flex align-items-center mb-2">
        <feather-icon
            icon="XIcon"
            size="24"
            class="close-modal"
            @click="show = !show"
        />
        <strong>{{ $t('creator.filters') }}</strong>
      </div>
    
      <b-row class="m-0">
        <b-col class="mb-2">
          <div class="d-flex justify-content-between">
            <strong class="text-muted d-block mb-1 w-100">{{ $t('creator.typeCompentation') }}</strong>
          </div>
          <b-form-checkbox-group  class="d-flex flex-wrap" v-model="compensation_type">
            <b-form-checkbox class="cursor-pointer" value="fixed">
              <strong>{{ $t('creator.fixed') }}</strong>
              <p class="avenir-medium">{{ $t('creator.textFixed') }}</p>
            </b-form-checkbox>

            <b-form-checkbox value="negotiable">
              <strong>{{ $t('creator.negotiable') }}</strong>
              <p class="avenir-medium">{{ $t('creator.textNegotiable') }}</p>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>

        <b-col class="col-12 d-flex flex-wrap mb-2">
          <strong class="text-muted d-block mb-1 w-100">{{ $t('creator.compesationRange') }}</strong>
          <vue-slider
            class="col-12 mb-2"
            v-model="compensation_range"
            :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :max="10000"
            :min="10"
            :interval="1"
            :tooltip-formatter="formatter_commas"
          ></vue-slider>
          <b-form-group :label="$t('creator.min')" label-for="input-followers-min" class="col-6 p-0 pr-1">
            <cleave
              :options="options_cleave.number"
              v-model="compensation_range[0]"
              class="form-control" 
            />
          </b-form-group>
          <b-form-group :label="$t('creator.max')" label-for="input-followers-max" class="col-6 p-0 pl-1">
            <cleave
              :options="options_cleave.number"
              v-model="compensation_range[1]"
              class="form-control" 
            />
          </b-form-group>
        </b-col>

        <b-col class="col-12 mb-2">
          <strong class="text-muted d-block mb-1 w-100">{{$t('creator.tags')}}</strong>
          <b-dropdown class="d-flex dropdown-add-tags" menu-class="w-100" variant="link" no-caret dropdown>
          <template #button-content>
            <vue-perfect-scrollbar class="scrollbar-container-tags">
              <div>
                <b-badge class="light-secondary-v2 mr-05" variant="light-secondary-v2" v-for="(tag, index) in tags" :key="index">
                  {{tag}}
                  <feather-icon icon="XIcon" @click="deleteTag(tag)"></feather-icon>
                </b-badge>
              </div>
              <feather-icon icon="ChevronDownIcon"></feather-icon>
            </vue-perfect-scrollbar>
          </template>
          <b-dropdown-header>
            <div>
              <b-form-input :placeholder="$t('creator.searchTags')" v-model="tag" @keyup.enter="addTag"></b-form-input>
            </div>
            <b-button :disabled="tags.find((t) => t === tag)" v-if="tag.length > 1" variant="success-add-tag" class="success-add-tag" @click="addTag"> {{$t('creator.add')}} "{{tag}}"</b-button>
          </b-dropdown-header>
        </b-dropdown>
        </b-col>

        <b-col class="col-12 mb-2">
          <strong class="text-muted d-block mb-1 w-100">{{ $t('creator.compensationPayout') }}</strong>
          <b-row class="m-0">
            <b-form-checkbox 
              v-for="compensation in $t('creator.compensations_type')" 
              :key="compensation.value"
              v-model="compensation_payout"
              :value="compensation.value"
              class="col-12 col-md-4 mb-02"
            >{{compensation.text}}</b-form-checkbox>
          </b-row>
        </b-col>

      </b-row>
    </div>
    <template #modal-footer>
      <b-col class="col-0 p-0 d-flex justify-content-end">
        <b-button variant="outline-secondary" @click="clearFilters">{{ $t('creator.clearFilter') }}</b-button>
        <b-button class="float-right ml-1" variant="primary" @click="sendFilters()">{{ $t('creator.filterButton') }}</b-button>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormCheckboxGroup,
  BButton,
  BCol,
  BModal,
  BRow,
  BFormGroup,
  BFormCheckbox,
  BBadge,
  BDropdown,
  BDropdownHeader,
  BFormInput,
} from 'bootstrap-vue';
import VueSlider from 'vue-slider-component';
import cleave from 'vue-cleave-component';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { separatebycomma, capitalize } from '@/libs/utils/formats'

export default {
  name: 'filterSearchCastingCall',
  components: {
    BFormCheckboxGroup,
    BButton,
    BCol,
    BModal,
    BRow,
    BFormGroup,
    BFormCheckbox,
    VueSlider,
    cleave,
    BBadge,
    BDropdown,
    BDropdownHeader,
    VuePerfectScrollbar,
    BFormInput,
  },
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    update_key: {
      type: Boolean,
    },
    filters_layout: {
      type: Array,
    }
  },
  data() {
    return {
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      compensation_type: null,
      compensation_range: ["10", "10000"],
      compensation_payout: [],
      tags: [],
      tag: '',
      show: this.open_modal,
      formatter_commas: v => `${separatebycomma(v)}`,
      filters: [
        {
          filter: 'compensation_type',
          text: 'compensation_type',
          models: [{name: 'compensation_type', value: null, default: null}],
        },
        {
          filter: 'compensation_range',
          text: 'compensation_range',
          models: [{name: 'compensation_range', value: null, default: ["10", "10000"]}],
        },
        {
          filter: 'compensation_payout',
          text: 'compensation_payout',
          models: [{name: 'compensation_payout', value: null, default: []},],
        },
        {
          filter: 'tags',
          text: 'tags',
          models: [{name: 'tags', value: null, default: []},],
        },
      ],
    }
  },
  computed: {
    compensation_payout_opctions() {
      return this.$t('creator.compensation_type').map((compensation) => {
        return {
          value: compensation.value,
          text: capitalize(compensation.value.replace(/_/g, ''))
        }
      });
    },
  },
  created() {
    this.putFiltersUsedMarked();
  },
  methods: {
    addTag() {
      this.tags.push(this.tag)
      this.tag = '';
    },
    deleteTag(name_tag) {
      this.tags = this.tags.filter((t) => t !== name_tag);
    },
    putFiltersUsedMarked() {
      if (this.filters_layout.length > 0) {
        this.filters = this.filters_layout;
        this.filters.map((fil) => {
          const model = fil.models.find((model) => model.name === fil.filter);
          if (model.value !== null) this[fil.filter] = model.value;
        });
      }
    },
    clearFilters() {
      this.filters.map((fil) => {
        fil.models.forEach((item) => {
          this[item.name] = item.default;
          item.value = null;
        });
      });
      this.$emit('filter', this.getFilters(), this.checkFiltersUsed().length, []);
    },
    changeFilter(name_filter, new_models) {
      const filter = this.filters.find(item => item.filter === name_filter)
      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)
        if (new_model) {
          model.value = new_model.value
        }
      })
    },
    getFilters() {
      return {
        type_compensation: this.compensation_type ? this.compensation_type : undefined,
        tags: this.tags.length > 0 ? this.tags : undefined,
        amount_min: this.compensation_range[0] !== "10" ? this.compensation_range[0] : undefined,
        amount_max: this.compensation_range[1] !== "10000" ? this.compensation_range[1] : undefined,
        compensations: this.compensation_payout.length > 0 ? this.compensation_payout : undefined,
      }
    },
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    sendFilters() {
      if (this.filters[3].models[0].value !== null && this.filters[3].models[0].value.length === 0) this.filters[3].models[0].value = null 
      if (this.filters[2].models[0].value !== null && this.filters[2].models[0].value.length === 0) this.filters[2].models[0].value = null 
      this.$emit('filter', this.getFilters(), this.checkFiltersUsed().length, this.filters);
    },
  },
  watch: {
    compensation_type(val) {
      if (val) {
        const new_models = [{name: 'compensation_type', value: val}]
        this.changeFilter('compensation_type', new_models);
      } else {
        this.changeFilter('compensation_type', [{name: 'compensation_type', value: null}]);
      }
    },
    compensation_range(val) {
      if (JSON.stringify(val) !== JSON.stringify(["10", "10000"])) {
        const new_models = [{name: 'compensation_range', value: val}]
        this.changeFilter('compensation_range', new_models);
      } else {
        this.changeFilter('compensation_range', [{name: 'compensation_range', value: null}]);
      }
    },
    compensation_payout(val) {
      const new_models = [{name: 'compensation_payout', value: val}]
      this.changeFilter('compensation_payout', new_models);
    },
    tags(val) {
      const new_models = [{name: 'tags', value: val}]
      this.changeFilter('tags', new_models);
    },
  }
}
</script>
<style scoped>
.scrollbar-container-tags {
  background-color: white;
  border: 1px solid #d8d6de;
  border-radius: 0.35rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 38px;
}
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
  margin-left: 8px
}
.success-add-tag {
  background-color: rgb(33, 180, 33) !important;
  text-align: center !important;
  padding-left: 30px !important;
  margin: 0.5em 0px 0px 0px;
  height: 30px;
  color: white !important;
  transition: all 300ms;
}
.success-add-tag:disabled {
  background-color: rgba(33, 180, 33, 0.459) !important;
  color: white !important;
}
.success-add-tag:disabled:hover {
  box-shadow: none !important;
  color: white !important;
}

.mb-02 {
  margin-bottom: 0.2em;
}
.close-modal {
  display: block;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
}
.close-modal:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
</style>

<style>
#modal-filter-casting-calls .modal-content {
  height: 750px !important;
  overflow: scroll;
}
.dropdown-add-tags .btn {
  width:  100% !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>